@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PP Mori";
  src: url(fonts/PPMori-Regular.otf);
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url(fonts/PPNeueMontreal-Regular.otf);
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-name-base {
  font-size: 43px;
  font-weight: 400;
  line-height: 21.75px;
  letter-spacing: -0.543749988079071px;
  text-align: center;
}

.p-base {
  font-size: 17px;
  font-weight: 400;
  line-height: 22.1px;
  letter-spacing: 0.5px;
  text-align: center;
}

.button-base {
  border: none;
  color: white;
  padding: 0px 40px 0px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  width: 117px;
  height: 48px;
}

.button-logout {
  background-color: #343434;
}